const GPSTrackingRouter = {
    path: '/gps-tracking',
    meta: {
        title: 'GpsTracking',
        icon: 'GpsTracking'
    },
    children: [
        {
            path: 'full-map-tracking/:id?',
            component: () => import('@/views/GPS-Tracking/FullMapTracking/full-map-tracking.vue'),
            name: 'FullMapTracking',
            meta: { title: 'FullMapTracking', icon: 'submenu' }
        },
        // {
        //     path: 'multi-vehicle-tracking',
        //     component: () => import('@/views/GPS-Tracking/MultiVehicleTracking/multi-vehicle-tracking.vue'),
        //     name: 'MultiVehicleTracking',
        //     meta: { title: 'MultiVehicleTracking', icon: 'submenu' }
        // },
        {
            path: 'vehicle-tracking-list',
            component: () => import('@/views/GPS-Tracking/VehicleTrackingList/vehicle-tracking-list.vue'),
            name: 'VehicleTrackingList',
            meta: { title: 'VehicleTrackingList', icon: 'submenu' }
        },
        {
            path: 'poi-management',
            component: () => import('@/views/GPS-Tracking/PoiManagement/poi-management.vue'),
            name: 'PoiManagement',
            meta: { title: 'PoiManagement', icon: 'submenu' }
        },
        {
            path: 'poi-form/:id?',
            component: () => import('@/views/GPS-Tracking/PoiManagement/poi-form'),
            name: 'PoiForm',
            hidden: true,
            meta: { title: 'PoiManagement', icon: 'submenu', activeMenu: '/gps-tracking/poi-management' }
        },
        {
            path: 'map-instance',
            component: () => import('@/views/GPS-Tracking/MapInstance/map-instance.vue'),
            name: 'MapInstance',
            meta: { title: 'MapInstance', icon: 'submenu' }
        },
        {
            path: 'map-instance-details/:id',
            component: () => import('@/views/GPS-Tracking/MapInstance/map-instance-details.vue'),
            name: 'MapInstanceDetails',
            hidden: true,
            meta: { title: 'MapInstanceDetails', icon: 'submenu', enableBack: true, backPage: 'MapInstance', activeMenu: '/gps-tracking/map-instance' }
        },
        // {
        //     path: 'historical-playback',
        //     component: () => import('@/views/GPS-Tracking/HistoricalPlayback/historical-playback.vue'),
        //     name: 'HistoricalPlayback',
        //     meta: { title: 'HistoricalPlayback', icon: 'submenu' },
        // },
        // {
        //     path: 'historical-playback/:id',
        //     component: () => import('@/views/GPS-Tracking/HistoricalPlayback/historical-playback-details.vue'),
        //     name: 'HistoricalPlaybackDetails',
        //     hidden: true,
        //     meta: { title: 'HistoricalPlaybackDetails', activeMenu: '/gps-tracking/historical-playback' }
        // },
        // {
        //     path: 'pto-log',
        //     component: () => import('@/views/GPS-Tracking/PtoLog/pto-log.vue'),
        //     name: 'PtoLog',
        //     meta: { title: 'PtoLog', icon: 'submenu' }
        // },
        
    ]
}

export default GPSTrackingRouter
